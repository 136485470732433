const logging = require('logging');

const _ = require('underscore');
const Backbone = require('Backbone');
const Marionette = require('Marionette');

const {
  getScrollParent,
  triggerResize
} = require('@common/libs/helpers/app/BrowserHelpers');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const VideoPlayerFactory = require('@common/components/video/VideoPlayerFactory');
const VideoTimeRemainingCallbackWrapper = require('@common/components/video/VideoTimeRemainingCallbackWrapper');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/zoomoverlay/ZoomOverlay');

const DzLinkContent = require('./DzLinkContent');
const MediaFactory = require('@common/libs/file/MediaFactory');
const FileFactory = require('@common/libs/file/FileFactory');
const ImageViewerFactory = require('@common/components/image/ImageViewerFactory');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const TimeLogConfig = require('@training/apps/base/models/TimeLogConfig');

const MIN_VIDEO_HEIGHT = 200;
const MAX_VIDEO_HEIGHT = 480;

class BroadcastMessagePage extends Marionette.LayoutView {
  regions() {
    return {
      dzArticleContent: '.broadcast-message__link-content',
      videoContentRegion: '.broadcast-message-video-wrapper',
      surveyOptionsRegion: '.js-survey__answer-options'
    };
  }

  behaviors() {
    return {
      ZoomOverlay: {
        imageWrapper: '.image-wrapper'
      },
      Resizable: {}
    };
  }

  getTemplate() {
    if (this.isFromMessageFeed) {
      return require('@training/apps/training/templates/BroadcastMessageDetailsPage.html');
    }
    return require('@training/apps/training/templates/BroadcastMessagePage.html');

  }

  templateHelpers() {
    const message = this.model.get('message');
    const subject = this.model.get('subject');
    const pointsString = this.rewardPoints === 1
      ? I18n.t('communications.rewardPointsSingular')
      : I18n.t('communications.rewardPoints', { points: this.rewardPoints });

    return {
      message: message != null ? message : '',
      subject: subject != null ? subject : '',
      dzLink: this.pageLink,
      hasVideo: Boolean(this.model.get('mediaLink')),
      communicationTime: dateHelpers.timeFromEvent( this.model.get('startDate') ),
      rewardPoints: !this.acknowledged && this.rewardPoints && this.rewardPoints > 0 ? pointsString : '',
      communicationTypeTitle: I18n.t(`communications.type.${ this.messageType }`),
      communicationIcon: `icon-${ this.messageType.toLowerCase() }`
    };
  }

  initialize(options = {}) {
    logging.info('BroadcastMessagePage - initialize');

    ({
      isFromMessageFeed: this.isFromMessageFeed,
      isConsolidatedCommsEnabled: this.isConsolidatedCommsEnabled
    } = options);

    this.pageLink = this.model.get('pageLink');
    this._imageMediaInfo = {};

    this.listenTo(this, 'image:clicked', (mediaId) => {
      window.app.layout.showMedia(this._imageMediaInfo[mediaId]);
    });

    if (this.pageLink) {
      this.dzContentModel = new Backbone.Model({
        page: this.pageLink.page
      });
    }

    this.onNext = this.onNext.bind(this);
    this._renderVideoPlayer = this._renderVideoPlayer.bind(this);
    this.imageMedia = {};
    this.mediaFiles = {};
    this.initializeImageViewer = this.initializeImageViewer.bind(this);
    this.loadImages = this.loadImages.bind(this);
    this.updateImageMedia = this.updateImageMedia.bind(this);
    this.fetchAndDisplayImage = this.fetchAndDisplayImage.bind(this);
    this.messageType = this.model.get('type');

    this.isSurveyType = this.messageType === 'POLL' || this.messageType === 'QUIZ';
    this.rewardPoints = this.model.get('rewardPoints');
    this.acknowledged = this.model.get('acknowledged') === true;

    this.shouldMarkAsRead = true;
  }

  onAttach() {
    window.apps.base.timeLogController.createStamp(TimeLogConfig.PageView.BroadcastMessage, this.model.get('id'));
  }

  loadImages(force = false) {
    const $images = this.$('.image-placeholder').addClass('image-wrapper');
    if ($images.length > 0 && (!this.haveImagesLoaded || force)) {
      this.haveImagesLoaded = true;
      $images.each((ind, img) => {
        const $img = $(img);
        this.updateImageMedia($img);
        this.fetchAndDisplayImage($img);
      });
    }
    return $images.length;
  }

  updateImageMedia($img) {
    const mediaId = $img.data('media-id');
    // Don't update the uuid if we already have it in memory. UUIDs should not change
    // On first load, we'll grab it from the placeholder div
    const uuid = this.imageMedia[mediaId] && this.imageMedia[mediaId].uuid || $img.data('uuid');
    const style = $img.attr('style');
    const classes = $img.attr('class');
    // divs will use the contents to store the alt text, img tags will use the alt attribute.
    const alt = $img.attr('alt') || $img.text();
    this.imageMedia[mediaId] = {
      uuid,
      style,
      classes,
      alt
    };
  }

  fetchAndDisplayImage($img) {
    const mediaId = $img.data('media-id');
    const uuid = this.imageMedia[mediaId].uuid;
    this.mediaFiles[mediaId] = MediaFactory.createMediaFromJSON(FileFactory, {
      type: 'image',
      id: mediaId,
      uuid
    });
    this.mediaFiles[mediaId].fetch().then(() => {
      this.initializeImageViewer(this.mediaFiles[mediaId], $img);
    });
  }

  initializeImageViewer(imageMedia, $imgHtml) {
    $imgHtml.removeClass('image-placeholder');

    let displayWidth;

    if ($imgHtml[0].style.width !== '' && $imgHtml[0].style.width !== '0px') {
      displayWidth = $imgHtml.css('width');
    }

    const imageViewerOptions = _.extend({
      // We only add a placeholder if there are no other child nodes and the parent is an anchor tag.
      // In that case we can blow everything away safely. Otherwise, we keep the existing children.
      keepExistingChildNodes: false,
      imgClass: $imgHtml.attr('class'),
      imgStyles: $imgHtml.attr('style'),
      displayWidth,
      replaceElement: true,
      fallbackAlt: this.imageMedia[imageMedia.get('id')].alt
    }, { media: imageMedia.attributes }, {
      $el: $imgHtml
    });
    const imageViewer = ImageViewerFactory.createViewerInstance(imageViewerOptions);
    this.listenTo(imageViewer.render(), 'image:loaded', (image) => {
      // Register it to zoom overlay
      const $image = this.$(image);
      // Linked images should not zoom
      if (!$image.parent().is('a')) {
        const mediaId = $image.attr('data-media-id');
        this._imageMediaInfo[mediaId] = this.mediaFiles[mediaId];
        $image.wrap('<div class="image-wrapper"></div>');
      }
    });
  }

  onShow() {
    logging.info('BroadcastMessagePage - onshow');
    window.app.layout.setTitle(this.model.get('subject'));

    if (!this.isFromMessageFeed && !this.isSurveyType) {
      this.showActionButton();
    }

    this.$videowrapper = this.$('.broadcast-message-video-wrapper');
    this.$videotitle = this.$('h1');

    const videoPlayerOptions = {
      userLanguage: window.apps.auth.session.user.get('language'),
      autoplay: false,
      maxHeight: this.calculatePlayerMaxHeight()
    };

    this._renderVideoPlayer(videoPlayerOptions);

    this.tableCssFixing();
    this._setImageInfoForZoomModal();
    // Backwards compatibility with old-style images above, new images below.
    this.loadImages();

    const dzContentRegion = this.getRegion('dzArticleContent');
    if (this.pageLink && dzContentRegion) {
      dzContentRegion.show(this.getContentView());
    }

    if (this.isSurveyType) {
      this.showSurveyOptions();
    }
  }

  showActionButton() {
    const hasPoints = this.rewardPoints != null && this.rewardPoints !== 0;
    const buttonOpts = {
      type: 'customText',
      text: I18n.t(hasPoints && !this.acknowledged ? 'communications.claimPoints' : 'communications.gotItButton')
    };

    if (this.isSurveyType) {
      buttonOpts.text = I18n.t('general.continue');
    }

    this.actionBar.setActionButton({
      ...buttonOpts,
      onClick: this.onNext
    });
  }

  showSurveyOptions() {
    // Need this check because if CC flag is turned off, older Polls & Quizzes will still be returned by the API on the
    // Feed page for now. This API does not return the answer data to render the options however.
    if (!this.isConsolidatedCommsEnabled && this.isFromMessageFeed) {
      return;
    }

    const optionsView = ViewControllerFactory.createLegacyView(require('@common/modules/react').ReactControllerDefinitionFactory({
      component: import('@training/apps/common/surveys/SurveyAnswerOptionsContextHost'),
      props: {
        survey: this.model.attributes,
        removeBroadcastFromQueue: this.options.removeBroadcastFromQueue,
        handleCommunicationResponse: this.handleCommunicationResponse.bind(this),
        handleSurveyDeletedError: this.handleSurveyDeletedError.bind(this)
      }
    }))

    this.getRegion('surveyOptionsRegion').show(optionsView);
  }

  handleCommunicationResponse() {
    this.options.removeBroadcastFromQueue(this.model.get('id'));
    this.showActionButton();
    _.defer(() => {
      const surveyRegion = this.getRegion('surveyOptionsRegion');
      if (surveyRegion) {
        surveyRegion.$el[0].scrollIntoView({ behavior: 'smooth'});
      }
    });
  }

  handleSurveyDeletedError() {
    this.options.removeBroadcastFromQueue(this.model.get('id'));
    this.shouldMarkAsRead = false;
    this.showActionButton();
  }

  calculatePlayerMaxHeight() {
    const parentHeight = $(getScrollParent(this.el)).height();
    const videoWrapperPadding = this.$videowrapper.outerHeight(true) - this.$videowrapper.height();
    const titleHeight = this.$videotitle.outerHeight(true);
    const allowableHeight = parentHeight - videoWrapperPadding - titleHeight;

    if (allowableHeight >= MAX_VIDEO_HEIGHT) {
      return MAX_VIDEO_HEIGHT;
    } else if (allowableHeight <= MIN_VIDEO_HEIGHT) {
      return MIN_VIDEO_HEIGHT;
    } return allowableHeight;
  }

  setPlayerMaxHeight() {
    const allowableHeight = this.calculatePlayerMaxHeight();
    const videoPlayerHeight = parseInt(this.videoPlayer.$el.find('video').height(), 10);
    this.videoPlayer.setMaxHeight(allowableHeight);

    if (videoPlayerHeight < allowableHeight) {
      const wrapperHeight = (videoPlayerHeight + 10) >= MAX_VIDEO_HEIGHT ? MAX_VIDEO_HEIGHT : videoPlayerHeight + 10;
      this.$videowrapper.height(wrapperHeight);
    } else {
      this.$videowrapper.height(allowableHeight);
    }
  }

  tableCssFixing() {
    //From the CSS 2.1 spec
    // 6.4.4 Precedence of non-CSS presentational hints
    // The UA may choose to honor presentational attributes in an HTML source document.
    // If so, these attributes are translated to the corresponding CSS rules with specificity equal to 0, and are treated as if they were inserted at the start of the author style sheet.
    // They may therefore be overridden by subsequent style sheet rules. In a transition phase, this policy will make it easier for stylistic attributes to coexist with style sheets.
    // So CSS styles always out-rank presentational attributes in HTML.

    const tableAttrArr = [{
      attr: 'border',
      css: 'border-width'
    },
    {
      attr: 'cellspacing',
      css: 'border-spacing',
      units: 'px'
    },
    {
      attr: 'cellpadding',
      css: 'padding',
      elements: 'td'
    } //should be a selector string for jquery
    ];

    _.each(this.$('.rte-message').find('table'), (table) => {
      const $table = $(table);
      _.each(tableAttrArr, (item) => {
        let val = $table.attr(item.attr);
        if (!val) {
          return;
        }

        if (item.units) {
          val += item.units;
        }

        if (item.elements) {
          $table.find(item.elements).css(item.css, val);
        } else {
          $table.css(item.css, val);
        }
      });
    });
  }

  _setImageInfoForZoomModal() {
    _.each(this.$('.rte-message').find('img'), (img, i) => {
      const $img = $(img);
      $img.attr('data-media-id', i);
      $img.on('load', triggerResize);
      $img.wrap(`<div class="image-wrapper" data-media-id="${ i }"></div>`);
      this._setImageInfo(img, i);
    });
  }

  _setImageInfo(img, i) {
    const image = {
      id: i,
      originalFile: {
        path: $(img).attr('src')
      },
      type: 'image'
    };

    this._imageMediaInfo[i] = image;
  }

  onNext() {
    if (apps.base.checkDoubleSubmit()) {
      return false;
    }
    if (this.nextClicked) {
      return false;
    }
    this.nextClicked = true;

    if (_.isFunction(this.options.complete)) {
      this.options.complete(this.shouldMarkAsRead);
    }

    if (!this.shouldMarkAsRead) {
      this.shouldMarkAsRead = true;
    }

    return undefined;
  }

  onResize() {
    if (this.videoPlayer != null) {
      this.videoPlayer.resize();
      this.setPlayerMaxHeight();
    }
  }

  _renderVideoPlayer(videoPlayerOptions) {
    logging.info('BroadcastMessagePage - renderVideoPlayer');

    const mediaLink = this.model.get('mediaLink');

    if (!mediaLink) {
      return;
    }

    this.videoPlayer = VideoPlayerFactory.createPlayerInstance({
      videoPackage: mediaLink,
      viewOptions: videoPlayerOptions
    });

    const videoFullscreenExitTimeOffset = TenantPropertyProvider.get().getProperty('videoFullscreenExitTimeOffset');

    VideoTimeRemainingCallbackWrapper({
      videoPlayer: this.videoPlayer,
      timeRemaining: videoFullscreenExitTimeOffset,
      callback: () => {
        this.videoPlayer.exitFullScreen();
      }
    });

    this.videoContentRegion.$el.addClass('active');
    this.videoContentRegion.show(this.videoPlayer);
  }

  getContentView() {
    return new DzLinkContent({
      model: this.dzContentModel
    });
  }

  destroy() {
    window.app.layout.dismissMedia();
    window.app.layout.resetLeftHeaderView();

    if (!this.isFromMessageFeed) {
      this.actionBar?.setActionButton(null);
    }

    super.destroy();
  }
}

module.exports = BroadcastMessagePage;
